import React from "react";

function ProjectCard({ image, title, link }) {
  const handleClick = () => {
    console.log(link);
    window.open(link, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "300px",
        margin: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <img
        src={image}
        alt={title}
        style={{ width: "100%", height: "200px", objectFit: "cover" }}
      />
      <div
        style={{
          textAlign: "center",
          padding: "10px",
          fontSize: "16px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        {title}
      </div>
    </div>
  );
}

export default ProjectCard;
