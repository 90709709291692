import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import ProjectCard from "./ProjectCard";
import snake from "../../Assets/snake.png";

function Projects() {
  const projectsData = [
    { image: snake, title: "Snake.JS", link: "https://snake.dtdsouza.dev" },
  ];

  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gridGap: "20px",
            padding: "20px",
          }}
        >
          {projectsData.map((card, index) => (
            <ProjectCard
              key={index}
              image={card.image}
              title={card.title}
              link={card.link}
            />
          ))}
        </div>
      </Container>
      <Particle />
    </Container>
  );
}

export default Projects;
